body,html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: capitalize;
  padding: 0;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-left h5{
  text-align: left;
}
.text-right h5{
  text-align: right;
}

/********************Navbar*********************/
.social-icons-header{
  margin-top: 5px;
  float: right;
}
.social-icons-header svg{
  color:#fff;
  line-height:30px;
  margin: 0 5px;
  text-decoration:none;
  border-radius: 20%;
  height: 25px;
  width: 25px;
}

.social-icons-header  svg i{
  line-height:30px;
  font-size:250px;
  transform: scale(1);

}
.social-icons-header a:hover svg{
  box-shadow: 0px 0px 150px #000000;
  z-index: 2;
  transition: 0.8s;
  -moz-transform: scale(1.5);
  transform: scale(1.2);
  
}
.social-icons-header .facebook:hover svg{
  color: #5b93e7;
}
.social-icons-header .twitter:hover svg{
  color: #00aced;
}
.social-icons-header .instagram:hover svg{
  color:#ea4c89 ;
}
/* .navbar img{
 float: left;
  height: 120px;
  width : 120px;
  padding-bottom:0.5rem;
 } */

 .navbar-nav .nav-link {
  font-size: 25px;
  color: wheat !important;
  font-weight: 300 !important;
  padding: 0.5rem 1rem 0.5rem 1rem !important;
  transition: 0.8s;
 }
 
 .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
}
.navbar-toggler {
  border-color: white !important;
} 
.navbar{
  background-color: black;
}
.navtext {
  padding-left: 0.5rem;
  text-transform:capitalize;
  color:white;
  font-size:15px;
  float: left;
}
.navbar-nav .nav-link:hover {
  color: gold !important;
  transition: 0.8s;
}
.fixed{
  position: fixed;
  width: 100%; 
  z-index: 2;
}
.header{
  background-color: black;
  display: flex;
  align-items: center;
  position: relative;
}
 .header .iconbtnn{
  font-size: 17px;
  color: #fff;
  float: left;
  justify-content: center;
}
.iconbtnn p{
  padding-top: 1vh;
}

.header .iconbtn{
  width: 25px;
  height: 25px;
  text-decoration: none;
  margin: 8px;
  position: relative;
  font-size: 20px;
  float: right;
  border: none;
  border-radius: 50px;
}

.iconbtn::before{
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  color: white;
}

.iconbtn::after{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius:1px ;
  z-index: 0;
  background: #3679dd;
  transition: 1s;
  border-radius: 30%;
}

.header .iconbtn:hover::after{
  transform: scale(1.5);
  opacity: 0;
}
.header .iconbtn:hover::before{
  color: white;
  transition: 1s;
}

.dropdown {
  position: relative;
  float: right;
}
#basic-nav-dropdown{
  color: white;
}

#basic-nav-dropdown:hover{
  color:gold ; 
}

/************************************************/
.pd-y {
  padding-top: 120px;
  padding-bottom: 120px;
}

.howitwrap {
	position: relative;
	padding:50px 0;
}

.section-title {
	margin: 0;
	padding-bottom: 50px;
	text-align: center;
	padding-bottom: 15px;
  text-transform: capitalize;
}
.howlist {
	list-style: none;
	margin-bottom: 20px;
}
.howitwrap .section-title h3:after {
	left: 0;
	margin-left: 0
}
.howitwrap .section-title h3:before {
	left: 26px;
	margin-left: 0
}

.howitwrap img {
  max-width: 100%;
  padding-bottom: 30px;
}

.howlist li .howbox:after {
	display: table;
	clear: both;
	content: '';
}
.howbox h4{
  color:#3679dd;
}
.howlist li .iconcircle {
	margin-bottom: 15px;
	float: left;
	margin:0 50px 40px 0;
	width: 50px;
	height: 50px;
	border-radius: 5px;
	border:1px solid #ddd;
	text-align: center;
	line-height: 40px;
  background-color: white;
  color: black;
}

.section-title h3 {
	position: relative;
	font-size: 25px;
	margin-bottom: 20px;
  margin-top: 15px;
	text-transform: uppercase;
	font-weight: 700;
	line-height: 32px;
}
.section-title h3 span {
  color: #3679dd;
  font-weight: 400;
  display:block;
  font-size: 36px;
}
.section-title p {
	max-width: 1000px;
  text-align:justify ; 
}
.section-title h5 {
	max-width: 90%;
  word-break: break-word;
  word-spacing: 0.1rem;
}
.titlee {
  text-align: center;
  padding: 15px;
  color: gold;
}
.stcontent {	
	padding:0 50px;
}
 #home-about{
   color: white;
 }
 
@media only screen and (max-width:1080px)
{
  .howbox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .howlist li .iconcircle{
    margin: 10px 0 10px 0 ;
  }
  .section-title h5 {
    max-width: 1000px;
    padding: 2px;
    font-size: 17px;
  }
  .section-title h3 span {
    font-size: 30px;
    }
    .animated-title > div div {
      font-size: 30px;
      padding: 2px 0;
      position: absolute;
    }
    .animated-title {
      color: #222;
      font-family: Roboto, Arial, sans-serif;
      height: 70vmin;
      left: 50%;
      position: absolute;
      top: 31%;
      transform: translate(-50%, -50%);
      width: 70vmin;
     
    }
}
@media only screen and (max-width:990px)
{
    .animated-title > div div {
      font-size: 30px;
      padding: 2px 0;
      position: absolute;
    }
    .animated-title {
      color: #222;
      font-family: Roboto, Arial, sans-serif;
      height: 70vmin;
      left: 50%;
      position: absolute;
      top: 40%;
      transform: translate(-50%, -50%);
      width: 70vmin;
     
    }
}
@media only screen and (max-width:480px)
{
  .howbox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .howlist li .iconcircle{
    margin: 10px 0 10px 0 ;
  }
  .carousel img{
    margin-top: 85px;
    }

    .animated-title {
      color: #222;
      font-family: Roboto, Arial, sans-serif;
      height: 75vmin;
      left: 50%;
      position: absolute;
      top: 40%;
      transform: translate(-50%, -50%);
      width: 320px;
    }
    .servicesbox2 {
      padding: 200px;
      text-align:center;
    }
  
} 
/************************************************************************/
.info-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.info-about {
  text-align: center;
  border: #e8e8e8 solid 2px;
  padding: 50px 20px;
  position: relative;
  margin-bottom: 25px;
  flex-basis:75%;
  flex-grow: 0;
	flex-shrink: 0;
}

.info-about.mg {
  margin: 0 5%;
  margin-bottom: 25px;
}

.info-about:hover h2 {
  color: gold;
  transition: 1s;
}
.info-about:hover p {
  color: white;
  transition: 1s;
}

.title{
  text-transform: capitalize;
  margin-bottom: 20px;
}

.info-paragraph {
  line-height: 1.6;
  color: black;
  font-size: 18px;
}

.effect .effect-H  {
  position: relative;
}

.effect:after {
  position: absolute;
  content: "";
  width: 0%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: #333;
  transition: width 1s;
}

.effect-H:after {
  position: absolute;
  content: "";
  height: 0%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: #333;
  transition:height 0.8s;
}

.effect-H:hover:after {
  height:100%;
  transition: 0.8s;
}

.effect:hover:after {
  width: 100%;
  transition: 0.8s;
}

/*********************Footer*********************/
.site-footer
{
  background-color:#a5a5a5;
  padding:45px 0 20px;
  font-size:15px;
  line-height:24px;
  color:#737373;
}
.site-footer hr
{
  border-top-color:#bbb;
  opacity:0.5
}
.site-footer hr.small
{
  margin:20px 0
}
.site-footer h6
{
  color:gold;
  font-size:16px;
  text-transform:uppercase;
  margin-top:5px;
}

.footer-links
{
  padding-left:0;
  list-style:none
}
.footer-links li
{
  display:block
}
.footer-links a 
{
  text-decoration: none;
  color:black;
}
.footer-links a:active,.footer-links a:focus,.footer-links a:hover
{
  color:gold;
}
.footer-links.inline li
{
  display:inline-block
}
.site-footer .social-icons
{
  text-align:right
}
.site-footer .social-icons a
{
  width:40px;
  height:40px;
  line-height:35px;
  margin-left:6px;
  margin-right:0;
  border-radius:100%;
  background-color:white;
  color: black;
}
.text-justify {
  color: black;
}
.copyright-text
{
  margin:0
}
.footer-about{
  list-style: none;
  display: flex;
  flex-direction:row;
  padding-left: 0;
  color:#000
}
.footer-about p{
  font-size: 13px;
  padding: 2px;
}

@media (max-width:991px)
{
  .site-footer [class^=col-]
  {
    margin-bottom:30px
  }
}
@media (max-width:767px)
{
  .site-footer
  {
    padding-bottom:0
  }
  .site-footer .copyright-text,.site-footer .social-icons
  {
    text-align:center
  }
}
.social-icons 
{
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.social-icons li ,
.footer-about li
{
  display:inline-block;
  margin-bottom:4px
}

.social-icons a{
  background-color:#eceeef;
  font-size:16px;
  display:inline-block;
  line-height:35px;
  width:50px;
  height:50px;
  text-align:center;
  margin-right:8px;
  border-radius:100%;
  -webkit-transition:all .2s linear;
  -o-transition:all .2s linear;
  transition:all .2s linear;
  transition: 0.8s;
}
.footer-about a{
  color: #000;
  transition: 1s;
}
 .location {
  
  color: black;
  display:inline-block;
  line-height:25px;
  width:30px;
  height:30px;
  text-align:center;
  margin-left:8px;
  border-radius:100%;
  -webkit-transition:all .2s linear;
  -o-transition:all .2s linear;
  transition:all .2s linear;
  transition: 0.8s;
}
.location a:active,.location:focus,.location:hover{
  background-color:rgb(44, 204, 44);
  color:#fff;
}
.social-icons a:active,.social-icons a:focus,.social-icons a:hover
{
  color:#fff;
}
.social-icons.size-sm a 
{
  line-height:35px;
  height:35px;
  width:34px;
  font-size:14px;
}
.footer-about .size-sm a
{
  line-height:35px;
  height:25px;
  width:24px;
  font-size:10px;
}
.social-icons a.facebook:hover
{
  background-color:#3b5998
}
.social-icons a.twitter:hover
{
  background-color:#00aced
}
.social-icons a.instagram:hover
{
  background-color:#ea4c89
}
/******************************************/
.pageTitle {
	background:url(/src/images/title-bg.jpg) no-repeat;
	width: 100%;
	padding: 105px 0;
  background-size: cover;
  color: white;
  padding-top: 28vh;
}
.servicesbox {
	margin: 70px 0;
  padding: 70px 0;
	text-align:center;
}
.pd-x {
  padding-top: 100px;
}

.servicesbox .section-title
,.servicesbox2 .section-title
{
  padding-bottom:10px;
}
.servicesbox .section-title h3,
.servicesbox2 .section-title h3{
  color:gold;
  font-size: 30px;
}
.servicesbox p ,
.servicesbox2 p{
	color: #fff;
	font-size: 18px;
	font-family: 'Roboto', sans-serif;
	line-height: 26px;
	font-weight: 300;
} 
.servicesbox2 {
  padding: 100px;
	text-align:center;
}

.btn-group button {
  border: 1px solid; 
  padding: 8px 15px; 
  cursor: pointer; 
  transition: 0.6s;
  font-size: 20px;
  text-transform: capitalize;
  font-weight: bold;
  float: left;
}
.btn-group:after {
  content: "";
  clear: both;
  display: table;
}
 @media only screen and (max-width:480px)
{
    .servicesbox2 {
      padding: 150px;
      text-align:center;
    }
  
}  
/************************************************************/
.about {
  position: relative;
  width: 100%;
  background-color: #a5a5a5;
}
.about button{
  margin-top:10px ;
}
.about .section-header {
  margin-bottom: 30px;
  margin-left: 30px;
}
.section-header{
  color:gold;
  padding-top: 50px;
}
.about .about-img {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: .5s;
}

.about .about-img::after {
  position: absolute;
  content: "";
  top: 60px;
  right: 60px;
  bottom: 60px;
  left: 60px;
  background: transparent;
  border: 30px solid;
  border-image: repeating-linear-gradient( 45deg, rgba(0,0,0,.05), rgba(0,0,0,.05) .5%, rgba(0,0,0,.1) .5%, rgba(0,0,0,.1) 1%) 30;
  z-index: 1;
}

.about .about-img:hover::after {
  border-image: repeating-linear-gradient( 45deg, rgba(0,0,0,.1), rgba(0,0,0,.1) .5%, rgba(0,0,0,.05) .5%, rgba(0,0,0,.05) 1%) 30;
}

.about .about-img-1 {
  position: relative;
  height: 100%;
  height: 100%;
  margin: 0 150px 150px 0;
}
.about .about-img-3 {
  margin:10px ;
  position: relative;
  height: 100%;
}

.about .about-img-2 {
  position: absolute;
  height: 100%;
  height: 100%;
  top: 150px;
  left: 150px;
  z-index: 1;
}

.about .about-img-1 img,
.about .about-img-2 img,
.about .about-img-3 img {
  position: relative;
  width: 100%;
}

 .about-text p {
  font-size: 16px;
  margin-left: 30px;
  color: white;
}

.serv{
  background-color: white;
}

@media (max-width: 480px) {
    .about .about-img {
        height: auto;
    }
    .about .about-img-1 {
      margin: 0 90px 100px 0;
    }
  
}
@media (max-width: 767.98px) {
  .about .about-img {
      margin-bottom: 30px;
      height: auto;
  }
}


/************************************************************************/

#team h2{
  color: white;
}
.user-pic {
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 100%;
  margin: 20px auto 20px;
  border-left: 5px solid black;
  border-right: 5px solid black;
  border-top: 5px solid gold;
  border-bottom: 5px solid gold;
}

.card-box {
  padding: 15px;
  background-color: #fdfdfd;
  margin: 20px 0px;
  border-radius: 10px;
  border: 1px solid #eee;
  box-shadow: 0px 0px 8px 0px #d4d4d4;
  transition: 0.5s;
  padding: 3px;
}
.card-box:hover {
  border: 3px solid gold;
}
.card-box p {
  color: #808080;
}
/*////////////////////////////////////////////*/
.Team{
  background-color: white;
}


/**********************************************/
.details{
  text-align: center;
}

.details h2{
  color:#243238;
  font-size:50px;
  margin: 0;
}

.main-box{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  text-transform: capitalize;
}

.box{
  width: 80%;
  margin: 10px;
  text-align: center;
  padding: 25px;
  border-radius: 20px;
  background-color:white;
  border: 3px solid #a5a5a5;
}
.box h3{
  font-weight: bold;
}

.box svg:hover{
  color: gold;
  transition:0.8s ;
}
.box button{
  border-radius: 10px;
  text-transform: capitalize;
}
.box p {
  position: relative;
  z-index: 2;
  color: black;
  font-size: 15px;
  font-weight: bold;
  padding: 15px;
}

.box h4{
  color: black;
}

.box .icon{
  text-align: center;
  display: inline-block;
  position: relative;
  color: black;
}

.line{
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-block;
  width: 15%;
  border: 2px solid gold;

}
@media only screen and (max-width: 720px){
  .box{
     max-width:100%;
    }
    .box p {
      font-size: 14px;
    }
}
@media only screen and (max-width: 480px){
  .box{
     max-width:100%;
     }
     .box p {
      font-size: 12px;
    }
}
/********************************************************/
.wel-title {
    text-transform: capitalize;
    font-weight: bold;
    color: #000;
}

.wel-head .linee{
    background-color: gold;
    width: 70px;
    height: 4px;
    border: none;
    margin-bottom: 100px;
}
.wel-head{
  display: flex;
  align-items: center;
  flex-direction:column;
}
/*************************************************************/
.contactOverlay {
  width: 100%;
  padding-top: 10%;
  padding-bottom: 10%;
  background-color: white;
}

.form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
form {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  background-color: #a5a5a5;
  overflow: hidden;
  border-radius: 50px;
  color: gold;
}
form h2{
  color:white
}
.formWord .button {
  height: 10%;
  border: 0;
  border-radius: 5px;
  background-color: black;
  -webkit-transition: 0.5s linear;
  -o-transition: 0.5s linear;
  transition: 0.5s linear;
  border-radius: 20px;
  color: white;
  font-weight: bold;
  font-size: 25px;
  
}
.formWord .button:hover {
  color: gold;
}
.formWord {
  width: 80%;
  padding: 4%;
}
.formWord h2 {
  font-size: 35px;
  font-weight: bold;
  font-family: "Josefin Sans", sans-serif;
  margin-bottom: 10%;
}
.formWord input {
  width: 100%;
  height: 50px;
  margin-bottom: 10%;
  border-radius: 10px;
  border: 0;
  margin-top: 2%;
  padding-left: 3%;
  background: #ffffff;
}
.formWord textarea {
  width: 100%;
  margin-bottom: 10%;
  border: 0;
  margin-top: 2%;
  height: 80px;
  color: black;
  border-radius: 10px;
  padding-left: 3%;
}

@media only screen and (max-width: 720px) {
  
  
  form{
    width: 100%;
    flex-direction: column;
  }
  .formWord{
    width: 100%;
  }
}
@media only screen and (max-width: 480px) {  
  form{
    width: 100%;
    flex-direction: column;
  }
  .formWord{
    width: 100%;
  }
}

.ai-page-header-div{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  background-image: url("./images/egypt.jpg");
  text-align: center;
  height: 50rem;
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,.2);
  display: flex;
  justify-content: center;
}
/* .ai-page-header-div .aboutUs-title {
 padding-bottom: 5em;

} */

@media (max-width: 815px) {
  .service-font h4{
    font-size: 14px;
  }
 .ai-page-header-div{ 
  justify-content: center;
  height: 30rem;
  margin-top: 6rem !important;
 }
  .ai-page-header-div h3{ 
    font-size: 17px !important;
  }
  .aboutUs-title {
    margin-top: 8em !important;
    margin-bottom: 3em !important;
    margin: 2em;
    margin-left: 0 !important;
    justify-content: flex-start !important;
  }
  .col-4:nth-child(2) {
    padding: 0 !important;
  }
  .home p{
    font-size: 15px !important;
  }
  .super {
    z-index: unset;
    left: unset;
  }

  h1,span {
    font-size: 20px !important;
    margin-bottom: 0;
  }
  .f-140 {
    margin-bottom: 0;
    top: -11px;
    left: 16px;
    font-size: 65px;
  }
  .aboutUs-title h1,h5,h3,span{
    font-size: 17px;
  }
  
  .aboutUs-title p{
    font-size: 10px;
   
  }

}

/***************************************************/
